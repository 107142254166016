<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},

};
</script>
<style>
@font-face {
  font-family: "iconfont";
  src: url("./assets/iconfont/iconfont.woff2") format("woff2"),
    url("./assets/iconfont/iconfont.woff") format("woff"),
    url("./assets/iconfont/iconfont.ttf") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
}
/* 禁止选中 */
.us {
  user-select: none;
}
/* 鼠标样式 */
.cp {
  cursor: pointer;
}
/* input placeholder 样式*/
input::-webkit-input-placeholder {
  color: var(--placeholder);
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--placeholder);
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--placeholder);
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder);
}
/* 全局滚动条样式*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #333;
}
::-webkit-scrollbar-corner {
  background: #179a16;
}
:root {
  --brand: #409eff;
  --success: #67c23a;
  --warning: #e6a23c;
  --danger: #f56c6c;
  --mainText: #303133;
  --text: #909399;
  --placeholder: #c0c4cc;
  --border: #dcdfe6;
  --transparent: transparent;
}
html {
  overflow: hidden;
}
input {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  color: var(--text);
  font-size: 12px;
  background: transparent;
}
</style>

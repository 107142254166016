// RSA数据加密
import Encrypt from 'encryptlong'
export default {
    encrypt(data, publicKey) {
        var encryptor = new Encrypt()
        publicKey = publicKey.substr(27)
        publicKey = publicKey.replace('-----END PUBLIC KEY-----', '');
        encryptor.setPublicKey(publicKey)
        const result = encryptor.encryptLong(data)
        return result
    },
}
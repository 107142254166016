import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routes = [
  {
    path: '/',
    name: 'DigWordsBao',
    redirect: '/DigWordsBao',
  },
  // 电商工厂线索宝
  {
    path: '/DsFactory',
    name: 'DsFactory',
    component: () => import('@/views/DSGCHtml/DsFactory/index'),
    children: [
      {
        path: '/DataBoard',
        name: 'DataBoard',
        component: () => import('@/views/DSGCHtml/DataBoard/index'),
      },
      {
        path: '/AdvertisementDs',
        name: 'AdvertisementDs',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/index'),
      },
      {
        path: '/businessOpportunityState',
        name: 'businessOpportunityState',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/businessOpportunityState'),
      },
      {
        path: '/addBusiness',
        name: 'addBusiness',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/addBusiness'),
      },
      {
        path: '/membershipList',
        name: 'membershipList',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/membershipList'),
      },
      {
        path: '/customizedDemand',
        name: 'customizedDemand',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/customizedDemand'),
      },
      {
        path: '/unreviewed',
        name: 'unreviewed',
        component: () => import('@/views/DSGCHtml/AdvertisementDs/unreviewed'),
      },
      {
        path: '/SetTemplateDs',
        name: 'SetTemplateDs',
        component: () => import('@/views/DSGCHtml/SetTemplateDs/index'),
      },
      {
        path: '/ManageUsersDs',
        name: 'ManageUsersDs',
        component: () => import('@/views/DSGCHtml/ManageUsersDs/index'),
      },
      {
        path: '/History',
        name: 'History',
        component: () => import('@/views/DSGCHtml/History/index'),
      },
      {
        path: '/Official',
        name: 'Official',
        component: () => import('@/views/DSGCHtml/Official/index'),
      },
      //公告留言
      {
        path: '/bulletinBoard',
        name: 'bulletinBoard',
        component: () => import('@/views/DSGCHtml/bulletinBoard/index'),
      },
      //会员记录
      {
        path: '/memberRecord',
        name: 'memberRecord',
        component: () => import('@/views/DSGCHtml/financeManagement/memberRecord'),
      },
      //积分记录
      {
        path: '/recordfPoints',
        name: 'recordfPoints',
        component: () => import('@/views/DSGCHtml/financeManagement/recordfPoints'),
      },

      //充值记录
      {
        path: '/rechargeList',
        name: 'rechargeList',
        component: () => import('@/views/DSGCHtml/financeManagement/rechargeList'),
      },
       //微信支付商机列表
      {
        path: '/WXpayment',
        name: 'WXpayment',
        component: () => import('@/views/DSGCHtml/financeManagement/WXpayment'),
      },
      //提现记录
      {
        path: '/WithdrawalAudit',
        name: 'WithdrawalAudit',
        component: () => import('@/views/DSGCHtml/financeManagement/WithdrawalAudit'),
      },
      
        //关于我们
      {
        path: '/AboutUs',
        name: 'AboutUs',
        component: () => import('@/views/DSGCHtml/systemManagement/AboutUs'),
      },
        //用户协议
      {
        path: '/userAgreement',
        name: 'userAgreement',
        component: () => import('@/views/DSGCHtml/systemManagement/userAgreement'),
      },
      //反馈列表
      {
        path: '/feedbackList',
        name: 'feedbackList',
        component: () => import('@/views/DSGCHtml/systemManagement/feedbackList'),
      },
      //设置关键词
      {
        path: '/Setkeywords',
        name: 'Setkeywords',
        component: () => import('@/views/DSGCHtml/systemManagement/Setkeywords'),
      },
      //积分比例
      {
        path: '/SetProportions',
        name: 'SetProportions',
        component: () => import('@/views/DSGCHtml/systemManagement/SetProportion'),
      },
      {
        path: '/blacklist',
        name: 'blacklist',
        component: () => import('@/views/DSGCHtml/systemManagement/blacklist'),
      },
      {
        path: '/exemptAudit',
        name: 'exemptAudit',
        component: () => import('@/views/DSGCHtml/systemManagement/exemptAudit'),
      },
      {
        path: '/qualificative',
        name: 'qualificative',
        component: () => import('@/views/DSGCHtml/systemManagement/qualificative'),
      },
      
      //-------------------------------------------------商机先锋企小鹿---------------------------------------------------
      //消息中心
      {
        path: '/messageCentre',
        name: 'messageCentre',
        component: () => import('@/views/DSGCHtml/businessPioneer/messageCentre'),
      },
      //审核中心
      {
        path: '/messageAuditing',
        name: 'messageAuditing',
        component: () => import('@/views/DSGCHtml/businessPioneer/messageAuditing'),
      },
      //用户管理
      {
        path: '/messageUser',
        name: 'messageUser',
        component: () => import('@/views/DSGCHtml/businessPioneer/messageUser'),
      },

    ],
  },
  // 爱采购后台挖词宝
  {
    path: '/center',
    name: 'center',
    component: () => import('@/views/Center/index'),
    children: [
      {
        path: '/digwordsbao',
        name: 'digwordsbao',
        component: () => import('@/views/DigWordsBao/index'),
      },
    ],
  },
  //挖词宝 产品复制打开无权限页面
  {
    path: '/NoPermission',
    name: 'NoPermission',
    component: () => import('@/views/NoPermission/index'),
  },

  // 爱采购后台产品添加及列表
  {
    path: '/productCopy',
    name: 'productCopy',
    component: () => import('@/views/ProductCopy/index'),
  },
  {
    path: '/productList',
    name: 'productList',
    component: () => import('@/views/ProductCopy/productList'),
  },
  //23.12月新增 智能优化&违禁词检测等
  {
    path: '/DiagnosisAndOptimize',
    name: 'DiagnosisAndOptimize',
    component: () => import('@/views/DiaKeyword/index'),
  },
  // 24.1月新增商品的操作1
  {
    path: '/ProductManager',
    name: 'ProductManager',
    component: () => import('@/views/productOperation/index'),
  },

  //23.12月新增 爱采购后台发布宝的产品标题
  {
    path: '/productTitle',
    name: 'productTitle',
    component: () => import('@/views/ProductTitle/index'),
  },

  //23.12月新增 爱采购后台阿拉宝
  {
    path: '/ALABao',
    name: 'alaBao',
    component: () => import('@/views/alaBao/index'),
    children: [
      {
        path: '/',
        name: 'main',
        component: () => import('@/views/alaBao/main'),
        children: [
          {
            path: 'productCopy',
            name: 'newproductCopy',
            component: () => import('@/views/ProductCopy/index'),
          },
        ],
      },
      {
        path: 'productList',
        name: 'newproductList',
        component: () => import('@/views/alaBao/productList'),
      },
    ],
  },
    // 爱采购后台AI智能
    {
      path: '/IntelligentPublishing',
      name: 'IntelligentPublishing',
      component: () => import('@/views/AISmart/index'),
      children: [
        {
          path: '/ReleaseBao',
          name: 'ReleaseBao',
          component: () => import('@/views/AISmart/aISmart'),
        },
        {
          path: '/maintain',
          name: 'maintain',
          component: () => import('@/views/AISmart/maintain'),
        },
        {
          path: '/publishedList',
          name: 'publishedList',
          component: () => import('@/views/AISmart/publishedList'),

        }
      ],
    },
  //公众号售卖商机
  {
    path: '/commercialOpportunity',
    name: 'commercialOpportunity',
    
    component: () => import('@/views/Binding/commercialOpportunity/index'),
    children: [
      {
        path: '/inquiry',
        name: 'inquiry',
        meta: {
          title: '商机售卖',
        },
        component: () => import('@/views/Binding/commercialOpportunity/inquiry'),
      },
      {
        path: '/member',
        name: 'member',
        meta: {
          title: '商机售卖',
        },
        component: () => import('@/views/Binding/commercialOpportunity/member'),
      },
      {
        path: '/myBusiness',
        name: 'myBusiness',
        meta: {
          title: '商机售卖',
        },
        component: () => import('@/views/Binding/commercialOpportunity/myBusiness'),

      }
    ],
  },
  //24.3.11新增AI智能发布
  {
    path: '/viedo',
    name: 'viedo',
    component: () => import('@/views/AISmart/viedo'),
  },
  //24.3.14 新增智能发布维护
  // {
  //   path: '/maintain',
  //   name: 'maintain',
  //   component: () => import('@/views/AISmart/maintain'),
  // },
  
  {
    path: '/setPublishing',
    name: 'setPublishing',
    component: () => import('@/views/productOperation/setPublishing'),
  },
  {
    path: '/ma',
    name: 'ma',
    component: () => import('@/views/AISmart/index2'),
  },
  // 公众号 账户绑定 跳转
  {
    path: '/1688Binding',
    name: '1688Binding',
    meta: {
      title: '绑定信息',
    },
    component: () => import('@/views/Binding/1688Binding'),
  },
  {
    path: '/ACGBinding',
    name: 'ACGBinding',
    meta: {
      title: '绑定信息',
    },
    component: () => import('@/views/Binding/ACGBinding'),
  },
  {
    path: '/estimateDetail',
    name: 'estimateDetail',
    meta: {
      title: '预估',
    },
    component: () => import('@/views/Binding/estimateDetail'),
  },
  {
    path: '/phoneBinding',
    name: 'phoneBinding',
    meta: {
      title: '绑定信息',
    },
    component: () => import('@/views/Binding/phoneBinding'),
  },
  {
    path: '/bindSuccess',
    name: 'bindSuccess',
    meta: {
      title: '绑定成功',
    },
    component: () => import('@/views/Binding/bindSuccess'),
  },
  {
    path: '/pushDetail',
    name: 'pushDetail',
    meta: {
      title: '线索详情',
    },
    component: () => import('@/views/Binding/pushDetail'),
  },
  {
    path: '/bindPhone',
    name: 'bindPhone',
    meta: {
      title: '绑定手机号',
    },
    component: () => import('@/views/Binding/bindPhone'),
  },
  {
    path: '/editInfo',
    name: 'editInfo',
    meta: {
      title: '编辑信息',
    },
    component: () => import('@/views/Binding/editInfo'),
  },
  {
    path: '/landerDetail',
    name: 'landerDetail',
    meta: {
      title: '登录信息',
    },
    component: () => import('@/views/Binding/landerDetail'),
  },
  {
    path: '/dailyDetail',
    name: 'dailyDetail',
    meta: {
      title: '日报',
    },
    component: () => import('@/views/Binding/dailyDetail'),
  },
  {
    path: '/replyDetail',
    name: 'replyDetail',
    meta: {
      title: '反馈',
    },
    component: () => import('@/views/Binding/replyDetail'),
  },
  {
    path: '/orderPrice',
    name: 'orderPrice',
    meta: {
      title: '询价订单',
    },
    component: () => import('@/views/Binding/orderPrice'),
  },
  // 运营猫数据汇总页面
  {
    path: '/summary',
    name: 'summary',
    meta: {
      title: '数据汇总',
    },
    component: () => import(`@/views/Summary/index`),
  },
  //老板回款报表
  {
    path: '/rateOfReturn',
    name: 'rateOfReturn',
    meta: {
      title: '回款数据报表',
    },
    component: () => import('@/views/Binding/rateOfReturn.vue'),
  },
  //员工
  {
    path: '/staffJurisdiction',
    name: 'staffJurisdiction',
    meta: {
      title: '回款数据报表',
    },
    component: () => import('@/views/Binding/staffJurisdiction.vue'),
  },
  //经理权限
  {
    path: '/manager',
    name: 'manager',
    meta: {
      title: '回款数据报表',
    },
    component: () => import('@/views/Binding/manager.vue'),
  },
  //总监权限
  {
    path: '/chiefInspector',
    name: 'chiefInspector',
    meta: {
      title: '回款数据报表',
    },
    component: () => import('@/views/Binding/chiefInspector.vue'),
  },
  // 爱采购  后台无ticket 及 404
  {
    path: '/NoTicket',
    name: 'NoTicket',
    component: () => import(`@/views/NoTicket/index`),
  },
  {
    path: '*',
    component: () => import(`@/views/NotFound/index`),
  },
]
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  routes,
})
router.beforeEach((to, from, next) => {
 
  if(to.fullPath=='/maintain'&&from.fullPath=='/'){
    next('/ReleaseBao')
  }
  if( to.meta.title){
    document.title = to.meta.title
  }else{
    document.title = '爱采购运营助手'
  }

  to.meta.title && (document.title = to.meta.title)
  next()
})
export default router

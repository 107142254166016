import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import Rsa from '@/utils/rsa.js'
import encryption from '@/utils/encryption.js'
import moment from 'moment'
import './assets/css/basic.css'
import Vuex from 'vuex'
import store from './store/index'
import VueClipBoard from 'vue-clipboard2' // 复制功能
import VueCropper from 'vue-cropper'  
import CropperImage from '@/components/CropperImage.vue' 
import * as echarts from 'echarts'


Vue.prototype.$echarts = echarts

const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}
  

Vue.directive('myTitle', {
  inserted: function (el, binding) {
    const { value } = binding
    // 方式1，直接给v-myTitle标签的 data-title 属性赋值
    if (el.dataset.title) { 
      document.title = el.dataset.title
    } 
    // 方式2，通过指令传参（{{xxx}}）
    else if (value && value.title) { 
      document.title = value.title
    }
  },
  update (el, binding) {
    const { value } = binding
    if (el.dataset.title) {
      document.title = el.dataset.title
    } else if (value && value.title) {
      document.title = value.title
    }
  }
})


Vue.component('CropperImage', CropperImage)


Vue.use(VueClipBoard)
Vue.prototype.$moment = moment
Vue.prototype.Rsa = Rsa
Vue.prototype.$encryption = encryption
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vuex)
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app')


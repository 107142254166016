export default {
  concealName(name){
    if(name){
      return name.substr(0,1) + new Array(name.length).join('*') + name.substr(2,1) 
    }else{
      return ''
    }
   
  },
  concealPhone(phone){
    if(phone){
      return  phone.replace(/^(\d{3})\d{6}(\d+)/, '$1****$2');
    }else{
      return ''
    }
}
}